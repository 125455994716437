import { useState, useEffect, useRef } from "react";

function UseRef() {
  const [name, setName] = useState("");
  const renderCount = useRef(1);
  const inputRef = useRef<HTMLInputElement>(null);
  const previousName = useRef("");

  useEffect(() => {
    renderCount.current = renderCount.current + 1;
  });

  function focus() {
    inputRef.current?.focus();
  }

  useEffect(() => {
    previousName.current = name;
  }, [name]);

  return (
    <section>
      <h2>useRef</h2>
      <button onClick={focus}>Focus input</button>
      <input ref={inputRef} type="text" name={name} onChange={(event) => setName(event.target.value)} />
      <p>
        My name is {name} and it used to be {previousName.current}
      </p>
      <p>I rendered {renderCount.current} times</p>
    </section>
  );
}

export default UseRef;
