import { useState } from "react";

function App() {
  const [value, setValue] = useState(false);
  const toggleValue = () => setValue((current) => !current);

  return (
    <section>
      <h2>useState</h2>
      <p>Renders the value in the UI when it changes</p>
      <button onClick={toggleValue}>{value ? "closed" : "opened"}</button>
    </section>
  );
}

export default App;
