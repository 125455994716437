import UseState from "./hooks/UseState";
import UseEffect from "./hooks/UseEffect";
import UseContext from "./hooks/UseContext";
import UseRef from "./hooks/UseRef";
import UseMemo from "./hooks/UseMemo";
import UseCallback from "./hooks/UseCallback";
import UseReducer from "./hooks/UseReducer";

function App() {
  return (
    <div>
      <UseState />
      <UseEffect />
      <UseContext />
      <UseRef />
      <UseMemo />
      <UseCallback />
      <UseReducer />
    </div>
  );
}

export default App;
