import { useState, useEffect } from "react";

const UseEffectAPI = () => {
  const [resourceType, setResourceType] = useState("posts");
  const [apiCallTtems, setApiCallTtems] = useState([]);

  useEffect(() => {
    fetch(`https://jsonplaceholder.typicode.com/${resourceType}`)
      .then((response) => response.json())
      .then((json) => setApiCallTtems(json));
  }, [resourceType]);

  return (
    <>
      <div>
        <button onClick={() => setResourceType("posts")}>Posts</button>
        <button onClick={() => setResourceType("users")}>Users</button>
        <button onClick={() => setResourceType("albums")}>Albums</button>
      </div>
      <h3>{resourceType}</h3>
      {apiCallTtems.map((item) => {
        return <pre>{JSON.stringify(item)}</pre>;
      })}
    </>
  );
};

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openApiCall, setOpenApiCall] = useState(false);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    // This runs first than the rest of the code of the useEffect (everytime the useEffect is used)
    return () => {
      // If you are adding and eventListener you should always remove it this way to avoid it being added multiple times
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section>
      <h2>useEffect</h2>
      <p>{windowWidth}</p>
      <p>Button opens API call useEffect demo, hidden by default because is expensive</p>
      <button onClick={() => setOpenApiCall((current) => !current)}>Toggle</button>
      {openApiCall && <UseEffectAPI />}
    </section>
  );
}

export default App;
