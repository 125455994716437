import { useState, useEffect, ChangeEvent, useCallback } from "react";

function List({ getItems }: { getItems: (value: number) => number[] }) {
  const [items, setItems] = useState<number[]>([]);

  useEffect(() => {
    setItems(getItems(5));
  }, [getItems]);

  return (
    <>
      {items.map((item) => (
        <div key={item}>{item}</div>
      ))}
    </>
  );
}

function App() {
  const [number, setNumber] = useState(1);
  const [dark, setDark] = useState(false);

  // With useCallback we are memoizing to the entire function, with useMemo we will only do it for the return value
  const getItems = useCallback(
    (incrementor: number) => {
      return [number + incrementor, number + 1 + incrementor, number + 2 + incrementor];
    },
    [number]
  );

  const theme = {
    backgroundColor: dark ? "#333" : "#FFF",
    color: dark ? "#FFF" : "#333",
  };

  const toggleTheme = () => setDark((current) => !current);
  const numberChange = (event: ChangeEvent<HTMLInputElement>) => setNumber(parseInt(event.target.value));

  return (
    <section style={theme}>
      <h2>useCallback</h2>
      <input type="number" value={number} onChange={numberChange} />
      <button onClick={toggleTheme}>Toggle theme</button>
      <List getItems={getItems} />
    </section>
  );
}

export default App;
