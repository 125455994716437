import { useState, useMemo, useEffect } from "react";

function App() {
  const [number, setNumber] = useState(0);
  const [dark, setDark] = useState(false);

  const doubleNumber = useMemo(() => {
    return slowFunction(number);
  }, [number]);

  const themeStyles = useMemo(() => {
    return {
      backgroundColor: dark ? "black" : "white",
      color: dark ? "white" : "black",
    };
  }, [dark]);

  useEffect(() => {
    console.log("Theme changed, had to use useMemo because object equality is different when re-rendered");
  }, [themeStyles]);

  const toggleTheme = () => setDark((prevDark) => !prevDark);

  return (
    <>
      <input type="number" value={number} onChange={(event) => setNumber(parseInt(event.target.value))} />
      <button onClick={toggleTheme}>Change Theme (this is instantaneous because of useMemo for slow operations in other parts of the app)</button>
      <div style={themeStyles}>{doubleNumber}</div>
    </>
  );
}

function slowFunction(num: number) {
  console.log("Calling Slow Function");
  for (let i = 0; i <= 10000; i++) {
    console.log("Function", i);
  }
  return num * 2;
}

export default App;
