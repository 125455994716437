import { useState, createContext, useContext } from "react";

const ThemeContext = createContext({ darkMode: false });

const useTheme = () => useContext(ThemeContext);

const ComponentN1 = () => {
  const insideContext = useTheme();
  return (
    <div style={{ color: insideContext.darkMode ? "gray" : "black" }}>
      Hello from N1
    </div>
  );
};

const ComponentN2 = () => {
  const insideContext = useTheme();
  return (
    <div style={{ color: insideContext.darkMode ? "gray" : "black" }}>
      Hello from N2
    </div>
  );
};

function UseContext() {
  const [darkTheme, setDarkTheme] = useState(false);

  const toggleTheme = () => setDarkTheme((current) => !current);

  return (
    <section>
      <ThemeContext.Provider value={{ darkMode: darkTheme }}>
        <h2>useContext</h2>
        <button onClick={toggleTheme}>
          Toggle to {darkTheme ? "Dark" : "Gray"}
        </button>
        <ComponentN1 />
        <ComponentN2 />
      </ThemeContext.Provider>
    </section>
  );
}

export default UseContext;
